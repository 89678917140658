<script lang="ts">
    import { Drawer as DrawerPrimitive } from 'vaul-svelte';
    import DrawerOverlay from './drawer-overlay.svelte';
    import { cn } from '$lib/utils.js';
    import { onMount, getContext } from 'svelte';

    type $$Props = DrawerPrimitive.ContentProps;

    let className: $$Props['class'] = undefined;
    export { className as class };

    type DrawerContext = {
        close: () => void;
        open: () => void;
    };

    let closeDrawer: () => void;

    function handleClose() {
        if (closeDrawer) {
            closeDrawer();
        }
    }

    const drawerContext = getContext('drawer') as DrawerContext;
    closeDrawer = drawerContext?.close;
</script>

<DrawerPrimitive.Portal>
    <DrawerOverlay />
    <DrawerPrimitive.Content
        class="{cn(
            'fixed inset-y-0 left-0 z-50 flex h-full w-[300px] flex-col border bg-background shadow-lg',
            className
        )}"
        data-direction="left"
        {...$$restProps}
    >
        <slot />
    </DrawerPrimitive.Content>
</DrawerPrimitive.Portal>
